<div class="container">
  <div class="content__section">

    <div fxLayout fxLayoutAlign="center">
      <!-- <img alt="" [src]="logoColor"> -->
      <img alt="" [src]="env.path.img + '/svg/404-person.svg'" style="width: 100px;">
    </div>

    <h3 class="text--center" style="margin-top: 30px;">
      Ah mince on s'est perdu !
    </h3>

    <p class="text--center" style="padding: 0 15px 30px" fxLayout fxLayoutAlign="space-around">
      On dirait que cette page a pris un autre chemin… ou n’existe plus. <br>Mais ne vous inquiétez pas, on va vous
      aider à retrouver votre route !
    </p>


    <p class="text--center" style="padding: 0px 15px" fxLayout="column" fxLayoutAlign="space-around">
      <!-- Choisissez votre destination :
      <br><br> -->
      <a [routerLink]="['']" fxFlex title="Aller à la page d'accueil">
        <mat-icon style="margin-right: 10px">home</mat-icon> Retour à l'accueil
      </a>
      <!-- <br>
      <a href="mailto:" fxFlex title="Nous conacter">
        <mat-icon style="margin-right: 10px">mail</mat-icon>Contactez-nous
      </a> -->
    </p>
  </div>
</div>